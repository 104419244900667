// paper & background
$paper: #ffffff;

// primary
$primaryLight: #eff3fb;
$primaryMain: #376ac7;
$primaryDark: #2f59a8;
$primary200: #a2bae5;
$primary800: #264989;


// secondary
$secondaryLight: #f0f5fb;
$secondaryMain: #006ece;
$secondaryDark: #0061bf;
$secondary200: #7d9bdf;
$secondary800: #005bb7;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;
$orange25: #fff2ee;
$orange100: #ffd7cb;
$orange400: #fc9460;
$orange600: #ff7b54;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

//blue
$blue25: #f8fcff;
$blue100: #b0bef7;
$blue150: #cbe1f1;
$blue175: #87b4d6;
$blue200: #92cbef;
$blue300: #128eed;
$blue350: #2e91ce;
$blue400: #2f89c8;
$blue450: #5975ea;
$blue500: #5e74ad;
$blue550: #545588;
$blue600: #215273;
$blue700: #03416b;
$blue800: #003761;
$blue900: #19315b;
//aqua
$aqua500: #25b5bd;

//green
$green10: #f5fffc;
$green15: #effffa;
$green25: #c9ffea;
$green50: #d7efb1;
$green75: #92eac8;
$green100: #6dffcb;
$green350: #26da92;
$green400: #2eba88;
$green450: #93af56;
$green500: #108c60;
$green600: #0d7223;

// grey
$grey50: #fafafa;
$grey60: #fbfbfb;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey325: #d2d2d2;
$grey400: #bdbbbb;
$grey500: #9e9e9e;
$grey550: #888888;
$grey600: #757575;
$grey700: #616161;
$grey800: #535353;
$grey900: #212121;

//red
$red50: #ffe7e6;
$red400: #ff5a3b;
$red700: #ff4155;

//yellow
$yellow50: #ffffe6;
$yellow700: #ffbf00;

//purple
$purple10: #fbf8ff;
$purple100: #fd9af9;
$purple650: #93278f;
$purple700: #7c007c;

//pink
$pink25: #fff6f6;
$pink50: #ffefef;
$pink100: #ffe6e9;
$pink300: #fdb7af;
$pink500: #ff9ca6;
$pink600: #faa098;
$pink800: #f96277;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;


// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
  // paper & background
  paper: $paper;

  // primary
  primaryLight: $primaryLight;
  primary200: $primary200;
  primaryMain: $primaryMain;
  primaryDark: $primaryDark;
  primary800: $primary800;

  // secondary
  secondaryLight: $secondaryLight;
  secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  secondaryDark: $secondaryDark;
  secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;
  orange25: $orange25;
  orange100: $orange100;
  orange400: $orange400;
  orange600: $orange600;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  //blue
  blue25: $blue25;
  blue100: $blue100;
  blue150: $blue150;
  blue175: $blue175;
  blue200: $blue200;
  blue300: $blue300;
  blue350: $blue350;
  blue400: $blue400;
  blue450: $blue450;
  blue500: $blue500;
  blue550: $blue550;
  blue600: $blue600;
  blue700: $blue700;
  blue800: $blue800;
  blue900: $blue900;

  //aqua
  aqua500: $aqua500;

  //green
  green10: $green10;
  green15: $green15;
  green25: $green25;
  green50: $green50;
  green75: $green75;
  green100: $green100;
  green350: $green350;
  green400: $green400;
  green450: $green450;
  green500: $green500;
  green600: $green600;

  // grey
  grey50: $grey50;
  grey60: $grey60;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey325: $grey325;
  grey400: $grey400;
  grey500: $grey500;
  grey550: $grey550;
  grey600: $grey600;
  grey700: $grey700;
  grey800: $grey800;
  grey900: $grey900;

  //red
  red50: $red50;
  red400: $red400;
  red700: $red700;

  //yellow
  yellow50: $yellow50;
  yellow700: $yellow700;

  //purple
  purple10: $purple10;
  purple100: $purple100;
  purple650: $purple650;
  purple700: $purple700;

  //pink
  pink25: $pink25;
  pink50: $pink50;
  pink100: $pink100;
  pink300: $pink300;
  pink500: $pink500;
  pink600: $pink600;
  pink800: $pink800;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;
}
